import { ErrorFullscreen } from '../templates/ErrorFullscreen'

export const Notfound = () => {
  return (
    <ErrorFullscreen
      status="4xx"
      title="お探しのページが見つかりません"
      text="URLの間違いがないか、再度ご確認ください"
    />
  )
}
