import { Box, Center, Heading, HStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { memo, VFC } from 'react'
import { config } from '../../config'
import { LoadingIcon } from '../atoms/icon/LoadingIcon'

type SceneLoadingType = {
  isOpenNavigation: boolean
}

export const SceneLoading: VFC<SceneLoadingType> = memo((props) => {
  const { isOpenNavigation } = props

  return (
    <>
      <Box
        as={motion.div}
        bg="gray.900"
        width="100vw"
        h="calc(var(--player-vh, 1vh) * 100)"
        position="absolute"
        top="0"
        left="0"
        zIndex="sceneLoading"
        key="sceneLoading"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: config.FADE_IN_MD.TIME,
            ease: config.FADE_IN_MD.EASE,
          },
        }}
        exit={{ opacity: 0 }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: config.FADE_OUT_SM.TIME,
          ease: config.FADE_OUT_SM.EASE,
        }}
      >
        <Box
          width={{ base: '100%', sm: isOpenNavigation ? `calc(100% - ${config.NAVIGATION_WIDTH}px)` : '100%' }}
          h="calc(var(--player-vh, 1vh) * 100)"
          position="absolute"
          top="0"
          right="0"
          transition="all 100ms cubic-bezier(.5, .5, .5, .1)"
        >
          <Center h="100%">
            <HStack spacing="28px">
              <LoadingIcon />
              <Heading as="p" fontSize={{ base: 'md', sm: 'xl' }}>
                Loading Scene...
              </Heading>
            </HStack>
          </Center>
        </Box>
      </Box>
    </>
  )
})
