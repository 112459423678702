import axios from 'axios'
import { useCallback } from 'react'
import { ListSceneDataType } from '../types/api/listSceneDataType'
import { usePlayerData } from './usePlayerData'

export const useListSceneData = () => {
  const { listSpaceData } = usePlayerData() // list space のデータ

  /**
   * spaceIdからlistSceneを取得
   * @param spaceId spaceId
   * @return scenes
   */
  const getListSceneData = useCallback(
    (spaceId: number) => {
      let scenes: null | ListSceneDataType = null
      for (let i = 0; i < listSpaceData.length; i++) {
        const space = listSpaceData[i]
        if (space.spaceId === spaceId) {
          scenes = space['scenes']
          break
        }
      }
      if (scenes === null) {
        alert('scenesが存在しません')
      } else {
        return scenes
      }
    },
    [listSpaceData]
  )
  return { getListSceneData }
}
