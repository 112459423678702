import axios from 'axios'
import { useCallback, useState } from 'react'
import { config } from '../config'
import axiosClient from '../services/axiosInstance'
import { SignedCookiesType } from '../types/api/signedCookiesType'
import { usePlayerData } from './usePlayerData'

export const useSignedCookies = () => {
  const { setStatusCode } = usePlayerData()
  const fetchSignedCookies = useCallback(async () => {
    try {
      const res = await axiosClient.get<SignedCookiesType>(config.PROJECT_SIGNED_COOKIES_API)
      if (res.data) {
        if (res.data.statusCode === 200) {
          const data = res.data.data
          const cookiesData = {
            'CloudFront-Policy': data['cloudFrontPolicy'],
            'CloudFront-Signature': data['cloudFrontSignature'],
            'CloudFront-Key-Pair-Id': data['cloudFrontKeyPairId'],
          }

          const setCookie = (cname, cvalue) => {
            document.cookie =
              cname + '=' + cvalue + ';path=/;domain=' + process.env.REACT_APP_COOKIE_DOMAIN + ';SameSite=None;Secure'
          }

          for (const key in cookiesData) {
            setCookie(key, cookiesData[key])
          }
          return res
        } else {
          alert('SignedCookiesの取得に失敗しました')
        }
      } else {
        alert('SignedCookiesの取得に失敗しました')
      }
    } catch (error) {
      if (error.response.status === 423) {
        setStatusCode(423)
        return
      }
      console.log('SignedCookiesの取得に失敗しました ', error)
      alert('SignedCookiesの取得に失敗しました')
    }
  }, [])

  return { fetchSignedCookies }
}
