import { Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { memo, VFC } from 'react'

export const LoadingIcon: VFC = memo(() => {
  const FACE_WIDTH = '15px' // 面の幅
  const WHITE = 'rgba(255, 255, 255, 0.16)'
  const BLUE = '#2067e3'
  const keyBaack = keyframes`
    from {transform: translateX(calc(${FACE_WIDTH} * 0))}
    to {transform: translateX(calc(${FACE_WIDTH} * -8))}
  `
  const keyFront = keyframes`
  from {transform: translateX(calc(${FACE_WIDTH} * -5))}
  to {transform: translateX(calc(${FACE_WIDTH} * 3))}
`
  const prefersReducedMotion = usePrefersReducedMotion()
  const animationBack = prefersReducedMotion ? undefined : `${keyBaack} infinite 2s linear`
  const animationFront = prefersReducedMotion ? undefined : `${keyFront} infinite 2s linear`

  /**
   *  面
   * */
  const Face = (props) => {
    return <Box position="absolute" top="0" h="12px" {...props} />
  }

  return (
    <Box w="40px" h="12px" position="relative" overflow="hidden">
      <Box position="absolute" top="0" left="0" animation={animationBack}>
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 1)`} backgroundColor={WHITE} />
        <Face w={`calc(${FACE_WIDTH} * 3)`} left={`calc(${FACE_WIDTH} * 4)`} backgroundColor={WHITE} />
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 9)`} backgroundColor={WHITE} />
      </Box>
      <Box position="absolute" top="0" left="0" animation={animationFront}>
        <Face w={`calc(${FACE_WIDTH} * 1)`} left={`calc(${FACE_WIDTH} * 0)`} backgroundColor={BLUE} />
        <Face w={`calc(${FACE_WIDTH} * 2)`} left={`calc(${FACE_WIDTH} * 3)`} backgroundColor={BLUE} />
      </Box>
    </Box>
  )
})
