import React, { createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react'

export type PageContextType = {
  page: string | null
  setPage: Dispatch<SetStateAction<string | null>>
}

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const PageProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [page, setPage] = useState<string | null>() // ページ
  return <PageContext.Provider value={{ page, setPage }}>{children}</PageContext.Provider>
}
