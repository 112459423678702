import React, { createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react'

export type FirstSceneLoadingContextType = {
  isFirstSceneLoading: boolean
  setIsFirstSceneLoading: Dispatch<SetStateAction<boolean>>
}

export const FirstSceneLoadingContext = createContext<FirstSceneLoadingContextType>({} as FirstSceneLoadingContextType)

export const FirstSceneLoadingProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [isFirstSceneLoading, setIsFirstSceneLoading] = useState(true) // 初回Sceneのメディアロードか
  return (
    <FirstSceneLoadingContext.Provider value={{ isFirstSceneLoading, setIsFirstSceneLoading }}>
      {children}
    </FirstSceneLoadingContext.Provider>
  )
}
