/**
 * INPUT、TEXTAREA、SELECT要素でなく、contentEditable属性がtrueに設定されていない場合にtrueを返す
 * @param event
 * @param key
 * @returns
 */

export const shouldHandleShortcut = (event: KeyboardEvent, key: string): boolean => {
  const targetElement = event.target as HTMLElement

  if (
    event.key === key &&
    targetElement.nodeName !== 'INPUT' &&
    targetElement.nodeName !== 'TEXTAREA' &&
    targetElement.nodeName !== 'SELECT' &&
    !targetElement.isContentEditable
  ) {
    return true
  }

  return false
}
