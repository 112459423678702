export const getTokenStorage = (name: string): string | null => {
  const data = localStorage.getItem('token')
  if (!data) {
    return null
  }
  const token = JSON.parse(data)
  return token.hasOwnProperty(name) ? token[name] : null
}

export const setTokenStorage = (value: string): void => {
  localStorage.setItem('token', JSON.stringify(value))
}
