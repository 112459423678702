import { Box, Button, Center, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { memo, VFC } from 'react'

import bg4xxImg from '../../images/bg_error_4xx.png'
import bg5xxImg from '../../images/bg_error_5xx.png'
import logoImg from '../../images/logo_whereness_set.svg'
import { Body1 } from '../atoms/text/Body1'
import { Headline2 } from '../atoms/text/Headline2'

type ErrorFullscreenType = {
  status: '4xx' | '5xx'
  title: string
  text: string
}

export const ErrorFullscreen: VFC<ErrorFullscreenType> = memo((props) => {
  const { status, title, text } = props

  return (
    <Box
      h="calc(var(--player-vh, 1vh) * 100)"
      bgImage={status === '4xx' ? bg4xxImg : bg5xxImg}
      bgSize={status === '4xx' ? 'contain' : { base: '97%', md: '80.1%' }}
      bgPosition={status === '4xx' ? '0 calc(50% - 109px)' : '55% 47%'}
      bgRepeat="no-repeat"
    >
      <Center h="100%">
        <Box p={status === '4xx' ? '67px 16px 0' : '55px 16px 0'} textAlign="center">
          <Headline2 mb="8px">{title}</Headline2>
          <Body1 mb={status === '4xx' ? '32px' : '28px'}>
            <Text>{text}</Text>
          </Body1>
          {status === '5xx' && (
            <Box mb="29px">
              <Button as="a" variant="ghost" href="https://whereness.io/jp/contact">
                お問い合わせ
              </Button>
            </Box>
          )}
          <Image src={logoImg} w="219px" alt="WHERENESS" display="inline-block" />
        </Box>
      </Center>
    </Box>
  )
})
