import { ErrorFullscreen } from '../templates/ErrorFullscreen'

export const Locked = () => {
  return (
    <ErrorFullscreen
      status="4xx"
      title="アクセスが制限されています"
      text="配信上限超過のため、アクセスが制限されています。プロジェクトのオーナーまでご確認ください"
    />
  )
}
