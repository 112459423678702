import { useCallback } from 'react'

export const useCookie = () => {
  // cookieがあるかを調べる
  const checkCookie = useCallback((cookieName) => {
    var cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      var nameValue = cookies[i].split('=')
      // cookieの名前が一致する場合はtrueを返す
      if (nameValue[0].trim() === cookieName) {
        return true
      }
    }
    // cookieが見つからない場合はfalseを返す
    return false
  }, [])

  return { checkCookie }
}
