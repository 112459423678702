import React, { createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react'

export type SceneControlWidthContextType = {
  sceneControlWidth: number
  setSceneControlWidth: Dispatch<SetStateAction<number>>
}

export const SceneControlWidthContext = createContext<SceneControlWidthContextType>({} as SceneControlWidthContextType)

export const SceneControlWidthProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [sceneControlWidth, setSceneControlWidth] = useState(0) // SceneControlの幅
  return (
    <SceneControlWidthContext.Provider value={{ sceneControlWidth, setSceneControlWidth }}>
      {children}
    </SceneControlWidthContext.Provider>
  )
}
