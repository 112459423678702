import { ApolloClient, InMemoryCache } from '@apollo/client'
import { config } from '../config'
import { getTokenStorage } from './webStorage'

const accessToken = getTokenStorage('accessToken')

export const apolloClient = new ApolloClient({
  uri: config.GRAPHQL_ENDPOINT,
  headers: { Authorization: `Bearer ${accessToken}` },
  cache: new InMemoryCache(),
})
