import { Route, Routes } from 'react-router-dom'
import { Forbidden } from '../components/pages/Forbidden'
import { Locked } from '../components/pages/Locked'
import { Notfound } from '../components/pages/Notfound'
import { Player } from '../components/pages/Player'
import { Redirect } from '../components/pages/Redirect'
import { usePlayerData } from '../hooks/usePlayerData'
import ProjectPassword from '../components/pages/ProjectPassword'

export const Router = () => {
  const { statusCode } = usePlayerData()

  const renderByStatus = (status) => {
    switch (status) {
      case 423:
        return <Locked />
      case 400:
        return <Forbidden />
      case 401:
        return <ProjectPassword />
      default:
        return <Player type="player" />
    }
  }

  const redirectPaths = ['/', '/jp', '/en']

  return (
    <Routes>
      {redirectPaths.map((path) => (
        <Route key={path} path={path} element={<Redirect />} />
      ))}
      <Route path="/here/:projectPublishId" element={renderByStatus(statusCode)} />
      <Route path="/here/preview/:projectPublishId" element={<Player type="preview" />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  )
}
