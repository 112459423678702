import { useToast, Box, RenderProps, HStack, IconButton, Spacer } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { CloseIcon } from '../components/atoms/icon/CloseIcon'
import { Body2 } from '../components/atoms/text/Body2'

type useMessageType = {
  text: string
  status: 'success' | 'error' | 'info'
}

export const useMessage = () => {
  const toast = useToast()

  /**
   * メッセージを表示する
   * @param props
   * @param props.text メッセージ
   * @param props.status メッセージのステータス
   */
  const showMessage = useCallback((props: useMessageType) => {
    const { text, status } = props
    let style

    if (status === 'success') {
      style = {
        duration: 2000,
        bg: 'green.500',
      }
    } else if (status === 'error') {
      style = {
        duration: 5000,
        bg: 'red.700',
      }
    } else if (status === 'info') {
      style = {
        duration: 2000,
        bg: 'gray.900',
      }
    }

    toast({
      description: text,
      status,
      duration: style.duration,
      isClosable: true,
      position: 'bottom',
      containerStyle: {
        maxWidth: '720px',
        minWidth: '320px',
        margin: '0 16px 16px',
      },
      render: ({ onClose }) => {
        return (
          <Box
            bg={style.bg}
            p="8px 24px"
            borderRadius="16px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(0, 0, 0, 0.02)"
          >
            <Body2>{text}</Body2>
          </Box>
        )
      },
    })
  }, [])

  return { showMessage }
}
