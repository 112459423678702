import { useCallback } from 'react'
import { ListLayerType } from '../types/api/listLayerType'
import { usePlayerData } from './usePlayerData'
import axiosClient from '../services/axiosInstance'
import { useMessage } from './useMessage'
import { config } from '../config'
import { SIDEBAR_DISPLAY_TYPE } from '../config/sidebarDisplayType'

export const useListSpace = () => {
  const { showMessage } = useMessage()
  const { setStatusCode, setListLayerData, setListSpaceData, setSidebarDisplayType } = usePlayerData()

  const fetchListSpace = useCallback(async () => {
    try {
      const res = await axiosClient.get<ListLayerType>(`${config.LIST_SPACE_API}?langCode=en`)

      if (res.data) {
        if (res.data.statusCode === 200) {
          const data = res.data.data
          const layers = data.layers
          const spaces = data.spaces
          const scenes = data.scenes
          const orderStructure = data.orderStructure

          let layerData = new Array()
          if (orderStructure) {
            if (orderStructure.layers) {
              orderStructure.layers.forEach((layer) => {
                let spaceData = new Array()
                if (layer.spaces) {
                  layer.spaces.forEach((space) => {
                    let sceneData = new Array()
                    if (space.scenes) {
                      space.scenes.forEach((scene) => {
                        const sceneId = scene.id
                        const sceneTitle = scenes?.find((scene) => scene.id === sceneId)?.title
                        const thumbnailUrl = scenes?.find((scene) => scene.id === sceneId)?.thumbnailUrl
                        sceneData.push({
                          sceneId: sceneId,
                          sceneTitle: sceneTitle,
                          thumbnailUrl: thumbnailUrl,
                        })
                      })
                    }
                    const spaceId = space.id
                    const spaceTitle = spaces?.find((space) => space.id === spaceId)?.title
                    const thumbnailUrl = spaces?.find((space) => space.id === spaceId)?.thumbnailUrl
                    spaceData.push({
                      spaceId: spaceId,
                      spaceTitle: spaceTitle,
                      thumbnailUrl: thumbnailUrl,
                      scenes: sceneData,
                    })
                  })
                }
                const layerId = layer.id
                const layerTitle = layers?.find((layer) => layer.id === layerId)?.title
                layerData.push({
                  layerId: layerId,
                  layerTitle: layerTitle,
                  spaces: spaceData,
                })
              })
            }
          }
          setListLayerData(layerData)

          let spacesData = new Array()
          layerData.forEach((layer) => {
            spacesData = spacesData.concat(layer.spaces)
          })
          setListSpaceData(spacesData)

          if (data.sidebarDisplayType) {
            setSidebarDisplayType(data.sidebarDisplayType)
          }

          return res
        } else {
          console.log('listSpaceの取得に失敗しました', res)
          showMessage({
            text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
            status: 'error',
          })
        }
      } else {
        console.log('listSpaceの取得に失敗しました', res)
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      }
    } catch (error) {
      console.log('listSpaceの取得に失敗しました', error)
      if (error.response.status === 400) {
        setStatusCode(400) // 閲覧権限がない場合は、ステータスを400に設定
      } else if (error.response.status === 401) {
        showMessage({
          text: '401エラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      } else if (error.response.status === 500) {
        showMessage({
          text: '500サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      } else {
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      }
    }
  }, [])

  return { fetchListSpace }
}
