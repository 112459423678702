import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState, VFC } from 'react'
import { Avatar, Box, Button, Flex, HStack, Image, Show, Text, useBreakpointValue } from '@chakra-ui/react'
import { useVideo, useAudio } from '@actualinc/whereness_player_package'

import { StartIcon } from '../atoms/icon/StartIcon'
import { usePage } from '../../hooks/usePage'
import logoImg from '../../images/logo_whereness_set.svg'
import dummyImg from '../../images/img_dummy.svg'
import { useDateFormat } from '../../hooks/useDateFormat'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SignedImage } from '../atoms/ui/SignedImage'
import { motion } from 'framer-motion'
import { config } from '../../config'
import { ExpandableText } from '../molecules/ExpandableText'
import { Headline1 } from '../atoms/text/Headline1'
import { Body1 } from '../atoms/text/Body1'
import { useIsAutoPlay } from '../../hooks/providers/useIsAutoPlay'
import { useViewingHistory } from '../../hooks/useViewingHistory'
import { useActiveNumber } from '../../hooks/useActiveNumber'
import { LockIcon } from '../atoms/icon/LockIcon'

type LandingType = {
  onLoaded: () => void
}

export const Landing: VFC<LandingType> = memo((props) => {
  const { onLoaded } = props
  const [bgHeight, setBgHeight] = useState(0)
  const refContents = useRef(null)
  const { video } = useVideo()
  const { audio } = useAudio()
  const { page, setPage } = usePage()
  const { dateFormat } = useDateFormat()
  const { projectInfoData, projectPublishId, sceneInfoData } = usePlayerData() // Project Info

  const { addHistory } = useViewingHistory()
  const { activeSceneId } = useActiveNumber()
  const { setStartButton } = useIsAutoPlay()
  const isMobile = useBreakpointValue({ base: true, sm: false })
  const isTablet = useBreakpointValue({ base: true, md: false })

  // SP時　背景画像の高さを設定
  useLayoutEffect(() => {
    if (isMobile) {
      if (refContents.current) {
        setBgHeight(window.innerHeight - refContents.current.offsetHeight)
      }
    }
  }, [isMobile, refContents])

  // Startボタンクリック
  const onClickStart = useCallback(
    (e) => {
      setStartButton(true)
      if (sceneInfoData?.contextType === 'video') {
        video.play()
      }
      if (sceneInfoData?.bgmUrl) {
        audio.play()
      }
      setPage('space')
      addHistory(activeSceneId)
    },
    [video, setPage, activeSceneId]
  )

  const showAvatar = useCallback(() => {
    if (!projectInfoData.options) {
      return true
    }
    if (!projectInfoData.options.hasOwnProperty('hideCreatedBy')) {
      return true
    }
    return !projectInfoData.options.hideCreatedBy
  }, [projectInfoData])

  return (
    <>
      <Box
        as={motion.div}
        width="100%"
        h="calc(var(--player-vh, 1vh) * 100)"
        position="absolute"
        top="0"
        left="0"
        zIndex="landing"
        key="landing"
        opacity="1"
        initial={{ opacity: 1 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: config.FADE_OUT_SM.TIME,
          ease: config.FADE_OUT_SM.EASE,
        }}
      >
        <Box w="100%" h="calc(var(--player-vh, 1vh) * 100)" position="absolute" top="0" left="0">
          {projectInfoData?.previewUrl === null ? (
            <Image
              src={dummyImg}
              w="100%"
              h="calc(var(--player-vh, 1vh) * 100)"
              objectFit="cover"
              position="absolute"
              zIndex="landingInner"
              top="0"
              left="0"
              onLoad={onLoaded}
            />
          ) : (
            <SignedImage
              w="100%"
              h={{ base: `${bgHeight}px`, sm: 'calc(var(--player-vh, 1vh) * 100)' }}
              objectFit="cover"
              src={projectInfoData?.previewUrl}
              position="absolute"
              zIndex="landingImage"
              top="0"
              left="0"
              onLoaded={onLoaded}
            />
          )}

          <Box
            bg="radial-gradient(153.42% 64.56% at 50% 35.44%, rgba(0, 0, 0, 0) 17.71%, rgba(0, 0, 0, 0.4) 60.94%)"
            w="100%"
            h="calc(var(--player-vh, 1vh) * 100)"
            position="absolute"
            zIndex="landingImage"
            top="0"
            left="0"
            display={{ base: 'none', sm: 'block' }}
          />

          <Box ref={refContents} width="100%" mx={{ md: 'auto' }} position="absolute" bottom="0" zIndex="landingImage">
            <Flex
              w={{ base: '100%', md: '88%', lg: '100%' }}
              flexDirection={{ base: 'column', md: 'row' }}
              backgroundColor={{ base: 'gray.800', sm: 'transparent' }}
              p={{ base: '28px 16px 22px 16px', sm: '0 32px 64px 32px', md: '0 0 48px', lg: '0 96px 64px 72px' }}
              borderRadius={{ base: '16px 16px 0 0', sm: '0' }}
              m={{ md: '0 auto', lg: '0' }}
              alignItems={{ md: 'center' }}
              justifyContent={{ md: 'space-between' }}
            >
              <Box
                width={{ md: '57%', lg: '60%' }}
                maxWidth={{ md: '60%', lg: '640px' }}
                mb={{ base: '31px', md: '0' }}
                mr={{ md: '32px', lg: '48px' }}
                flexShrink={{ md: 0 }}
              >
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <Headline1 as="h1">{projectInfoData?.title}</Headline1>
                  {projectInfoData?.publishStatus === 'password' ? (
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '16px',
                        padding: '8px',
                        background: 'rgba(0, 0, 0, 0.06)',
                      }}
                    >
                      <LockIcon style={{ height: '24px', width: '24px' }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {projectInfoData?.description && (
                  <Box whiteSpace="break-spaces" mt={{ base: '10px', md: '26px' }}>
                    {isTablet ? (
                      <ExpandableText text={projectInfoData?.description} />
                    ) : (
                      <Body1>
                        <Text>{projectInfoData?.description}</Text>
                      </Body1>
                    )}
                  </Box>
                )}
              </Box>
              <Box w={{ md: '40%' }} maxWidth={{ md: '360px' }} flexShrink={{ md: 0 }}>
                <Button
                  onClick={onClickStart}
                  variant="solid"
                  size="lg"
                  leftIcon={<StartIcon boxSize={{ base: '25px', sm: '33px' }} />}
                >
                  Start
                </Button>
                <Image
                  src={logoImg}
                  w={{ base: '180px', md: '219px' }}
                  h={{ base: '46px', md: '56px' }}
                  position={{ md: 'fixed' }}
                  top={{ md: '0' }}
                  right={{ md: '0' }}
                  mx={{ base: 'auto', md: '0' }}
                  mt={{ base: '27px', md: '0' }}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </>
  )
})
