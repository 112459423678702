import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, FormControl, Input, Text, Flex, Image, extendTheme } from '@chakra-ui/react'
import { usePlayerData } from '../../hooks/usePlayerData'
import { PasswordIcon } from '../atoms/icon/PasswordIcon'
import logoImg from '../../../src/images/bg_password.svg'
import "../../App.css"
export const theme = extendTheme({
  breakpoints: {
    sm: '520px',
    md: '1300px',
  },
})
interface PasswordLoginFormValues {
  password: string
}

const ProjectPassword: React.FC = () => {
  const { projectPassword, setProjectPassword, setStatusCode } = usePlayerData()
  const [passwordError, setPasswordError] = useState<string>(projectPassword ? 'パスワードが一致しません' : '')
  const validationSchema = Yup.object({
    password: Yup.string().required('Password is required'),
  })

  const formik = useFormik<PasswordLoginFormValues>({
    initialValues: { password: '' },
    validationSchema,
    onSubmit: async (values) => {
      setStatusCode(200)
      setProjectPassword(values.password)
    },
  })
  return (
    <>
      <Flex
        direction="column"
        align="center"
        justify="center"
        overflow="hidden"
        position="relative"
        height="100vh"
        padding={4}
        style={{ backgroundColor: '#292929' }}
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          width="full"
          maxWidth="400px"
          pb="10px"
        >
          <Box mb={4} pb={2.5}>
            <Flex justify="center">
              <PasswordIcon style={{
                marginBottom: '20px',
                width: '32px',
                height: '42px',
                left: '8px',
                top: '3px',
              }} />
            </Flex>
            <Text
              fontSize="16px"
              lineHeight="28px"
              textAlign="center"
              color='#FFFFFF'
              opacity='76%'
              fontFamily="Lato"
              fontStyle="normal"
              fontWeight="400"
              width="388px"
              height="28px"
            >
              限定公開ページ
            </Text>
          </Box>
          <Box position="absolute" bottom={{
            base: '-9vw',
            sm: '-3vw',
            md: '-5vw',
          }}
            right={{
              base: '-5vw',
              sm: '-15vw',
            }} width="full" zIndex={0}>
            <Image src={logoImg} alt="WHERENESS" objectFit="contain" height="auto" className='password-logo'/>
          </Box>
          <form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            <FormControl mb={4} isRequired>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder="パスワード"
                style={{
                  backgroundColor: '#3e3e3e',
                  border: passwordError ? '2px solid #F26C57' : 'none', boxShadow: 'none',
                }}
                onFocus={(e) => {
                  e.target.style.border = '2px solid #2067E3'
                }}
                value={formik.values.password}
                onChange={(e) => {
                  formik.handleChange(e)
                  setPasswordError('')
                  setProjectPassword(e.target.value)
                }}
                onBlur={formik.handleBlur}
              />
              <p className="px-2 font-lato leading-5" style={{ color: '#F26C57', fontSize: '12px', marginTop: '4px' }}>
                {passwordError}
              </p>
            </FormControl>
            <Flex direction="column" style={{ width: '100%', maxWidth: '400px' }}>
              <Button
                type="submit"
                py={5}
                fontWeight="semibold"
                fontSize="sm"
                isDisabled={!formik.dirty || !formik.isValid}
                mt={4}
                colorScheme={formik.dirty && formik.isValid ? 'blue' : 'gray'}
                style={{
                  backgroundColor: formik.dirty && formik.isValid ? '#2067E3' : '#3e3e3e',
                  borderRadius: '10px',
                }}
              >
                送信
              </Button>
            </Flex>
          </form>
        </Flex>
        <span
          style={{
            position: 'absolute',
            right: '-40px',
            bottom: '-5px',
            width: "339px",
            height: "56px",
            fontSize: '0.75rem',
            fontFamily: 'TT Travels Next',
            color: '#0046D7',
            lineHeight: "56px",
            fontWeight: 800,
            letterSpacing: "0.05em",
            textTransform: 'uppercase'
          }}
        >
          welcome to the world out of frame
        </span>
      </Flex>
    </>
  )
}

export default ProjectPassword
