import { Heading, HeadingProps } from '@chakra-ui/react'
import { memo, FC } from 'react'

export const Headline2: FC<HeadingProps> = memo(({ children, ...props }) => {
  return (
    <Heading
      fontWeight="600"
      fontSize={{ base: '20px', md: '28px' }}
      lineHeight={{ base: '28px', md: '36px' }}
      letterSpacing="-0.01em"
      {...props}
    >
      {children}
    </Heading>
  )
})
