import { ErrorFullscreen } from '../templates/ErrorFullscreen'

export const Forbidden = () => {
  return (
    <ErrorFullscreen
      status="4xx"
      title="アクセスが許可されていません"
      text="プロジェクトのオーナーまでご確認ください"
    />
  )
}
