import { Box, BoxProps } from '@chakra-ui/react'
import { memo, FC } from 'react'

export const Body1: FC<BoxProps> = memo(({ children, ...props }) => {
  return (
    <Box fontSize={{ base: '14px', lg: '16px' }} lineHeight={{ base: '24px', lg: '28px' }} fontWeight="400" {...props}>
      {children}
    </Box>
  )
})
