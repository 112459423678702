import { useCallback, useEffect, useState } from 'react'

const getVh = () => Number((window.innerHeight * 0.01).toFixed(2))

let count = 0

export const useVH = (): number => {
  const [vh, setVh] = useState(0)
  const updateVh = useCallback(() => {
    const newVh = getVh()

    document.documentElement.style.setProperty('--player-vh', `${newVh}px`)
    setVh(newVh)
  }, [setVh])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      count += 1
      updateVh()
      window.addEventListener('resize', updateVh)
    }
    return () => {
      window.removeEventListener('resize', updateVh)
      count -= 1
      if (count === 0) {
        document.documentElement.style.removeProperty('--player-vh')
      }
    }
  }, [updateVh])

  return vh
}
