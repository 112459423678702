import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'

export type FullscreenModalContextType = {
  isFullscreenModal: boolean
  setIsFullscreenModal: Dispatch<SetStateAction<boolean>>
}

export const FullscreenModalContext = createContext<FullscreenModalContextType>({} as FullscreenModalContextType)

export const FullscreenModalProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [isFullscreenModal, setIsFullscreenModal] = useState(false)

  return (
    <FullscreenModalContext.Provider value={{ isFullscreenModal, setIsFullscreenModal }}>
      {children}
    </FullscreenModalContext.Provider>
  )
}
