import React, { useEffect, useLayoutEffect, useRef, useState, VFC } from 'react'
import { Box, Button, Collapse, Text } from '@chakra-ui/react'
import { Body1 } from '../atoms/text/Body1'

type ExpandableTextType = {
  text: string
  limit?: number
}

export const ExpandableText: VFC<ExpandableTextType> = ({ text, limit = 41 }) => {
  const [showFullText, setShowFullText] = useState(false)
  const [truncatedTextHeight, setTruncatedTextHeight] = useState(0)
  const refTruncatedText = useRef(null)

  // トリミングしたテキストの高さを取得
  useLayoutEffect(() => {
    if (text.length > limit) {
      if (refTruncatedText.current) {
        setTruncatedTextHeight(refTruncatedText.current.clientHeight - 10)
      }
    }
  }, [text, refTruncatedText])

  const truncatedText = text.slice(0, limit) + '…'
  const toggleShowFullText = () => setShowFullText((prev) => !prev)

  return (
    <>
      {text.length > limit ? (
        <Box position="relative" minH={`${truncatedTextHeight}px`}>
          {!showFullText && (
            <Box ref={refTruncatedText} position="absolute">
              <Body1 mb="-7px">
                <Text>{truncatedText}</Text>
              </Body1>
              <Button onClick={toggleShowFullText} variant="link" size="sm">
                もっと見る
              </Button>
            </Box>
          )}

          <Collapse in={showFullText} startingHeight={truncatedTextHeight} transition={{ enter: { duration: 0.12 } }}>
            <Body1 opacity={showFullText ? 1 : 0} pointerEvents="none">
              <Text>{text}</Text>
            </Body1>
          </Collapse>
        </Box>
      ) : (
        <Body1>
          <Text>{text}</Text>
        </Body1>
      )}
    </>
  )
}
