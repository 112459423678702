import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { ApolloProvider } from '@apollo/client'
import { WherenessProvider } from '@actualinc/whereness_player_package'

import { Router } from './router/Router'
import { PageProvider } from './providers/PageProvider'
import { PlayerDataProvider } from './providers/PlayerDataProvider'
import { ActiveNumberProvider } from './providers/ActiveNumberProvider'
import { FirstSceneLoadingProvider } from './providers/FirstSceneLoadingProvider'
import { SceneControlWidthProvider } from './providers/SceneControlWidthProvider'
import { IsAutoPlaqyProvider } from './providers/IsAutoPlayProvider'
import { FullscreenModalProvider } from './providers/FullscreenModalProvider'
import { apolloClient } from './services/apolloClient'
import theme from './components/theme/theme'
import { useVH } from './hooks/useVH'
import { IsPreviousPausedProvider } from './providers/IsPreviousPausedProvider'

function App() {
  const vh = useVH()

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <PlayerDataProvider>
          <ActiveNumberProvider>
            <PageProvider>
              <FirstSceneLoadingProvider>
                <SceneControlWidthProvider>
                  <FullscreenModalProvider>
                    <IsPreviousPausedProvider>
                      <IsAutoPlaqyProvider>
                        <WherenessProvider>
                          <ChakraProvider theme={theme}>
                            <BrowserRouter>
                              <Router />
                            </BrowserRouter>
                          </ChakraProvider>
                        </WherenessProvider>
                      </IsAutoPlaqyProvider>
                    </IsPreviousPausedProvider>
                  </FullscreenModalProvider>
                </SceneControlWidthProvider>
              </FirstSceneLoadingProvider>
            </PageProvider>
          </ActiveNumberProvider>
        </PlayerDataProvider>
      </ApolloProvider>
    </>
  )
}

export default App
