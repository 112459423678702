import { Box, Center, Image } from '@chakra-ui/react'
import { memo, useEffect, useState, VFC } from 'react'
import { config } from '../../config'
import logoSymbol from '../../images/logo_whereness_symbol.svg'
import logoType from '../../images/logo_whereness_type.svg'
import { motion } from 'framer-motion'

type Props = {
  degree: number
}

export const Loading: VFC<Props> = memo((props) => {
  const { degree } = props
  const [isVisibleLogo, setIsVisibleLogo] = useState(true)

  useEffect(() => {
    return () => {
      setIsVisibleLogo(false)
    }
  }, [])

  return (
    <>
      <Box
        as={motion.div}
        width="100%"
        position="absolute"
        zIndex="loading"
        key="loading"
        initial={{ opacity: 1 }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: config.FADE_OUT_SM.TIME,
          ease: config.FADE_OUT_SM.EASE,
          delay: config.FADE_OUT_SM.TIME,
        }}
      >
        <Box bg="gray.900" h="calc(var(--player-vh, 1vh) * 100)">
          <Center h="100%">
            <Box
              as={motion.div}
              key="logo"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: config.FADE_IN_SM.TIME,
                  ease: config.FADE_IN_SM.EASE,
                },
              }}
              exit={{ opacity: 0 }}
              // @ts-ignore no problem in operation, although type error appears.
              transition={{
                duration: config.FADE_OUT_SM.TIME,
                ease: config.FADE_OUT_SM.EASE,
              }}
            >
              <Box>
                <Image
                  src={logoSymbol}
                  w={{ base: '112px', md: '177px' }}
                  m={{ base: '0 auto 15px', md: '0 auto 19px' }}
                  alt="WHERENESS"
                />
                <Image
                  src={logoType}
                  w={{ base: '144px', md: '226px' }}
                  mb={{ base: '48px', md: '72px' }}
                  alt="WHERENESS"
                  display="block"
                />
                <Box w="100%" h={{ base: '3px', md: '4px' }} bg="white.50" position="relative" overflow="hidden">
                  <Box
                    as={motion.div}
                    w="100%"
                    h={{ base: '3px', md: '4px' }}
                    bg="blue.500"
                    position="absolute"
                    animate={{
                      transform: ['translateX(-100%)', 'translateX(100%)'],
                    }}
                    // @ts-ignore no problem in operation, although type error appears.
                    transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
                  />
                </Box>
                {/* <Progress w="100%" h="4px" value={degree} /> */}
              </Box>
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  )
})
