import axios from 'axios'
import { useCallback } from 'react'
import { config } from '../config'
import { VIEW_TYPE } from '../config/playerData'
import { setTokenStorage } from '../services/webStorage'
import { viewType } from '../types/playerDataType'
import { useMessage } from './useMessage'
import { usePlayerData } from './usePlayerData'

type fetchAuthType = {
  type: viewType
  projectPublishId: string
}

export const useAuth = () => {
  const { setStatusCode, projectPassword } = usePlayerData()
  const { showMessage } = useMessage()
  const fetchAuth = useCallback(async (props: fetchAuthType) => {
    const { type, projectPublishId } = props
    try {
      const res = await axios.post(
        config.PLAYER_AUTH_API,
        {
          projectId: `${projectPublishId}`,
          projectPassword: projectPassword
        },
        {
          headers: { 'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}` },
          withCredentials: true,
        }
      )

      if (res.data) {
        if (res.data.statusCode === 200) {
          const auth = res.data.data
          setTokenStorage(auth)
        } else {
          console.log('authの取得に失敗しました', res)
          showMessage({
            text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
            status: 'error',
          })
        }
        return res
      } else {
        console.log('authの取得に失敗しました', res)
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      }
    } catch (error) {
      console.log('authの取得に失敗しました', error)
      if (
        type === VIEW_TYPE.PREVIEW &&
        error.response.status === 401 &&
        error.response.data.message === 'UNAUTHORIZED'
      ) {
        // preview時cms_access_tokenが一致しない場合は、Studioにリダイレクト
        window.location.replace(process.env.REACT_APP_STUDIO_URL)
      } else if (error.response.status === 400) {
        setStatusCode(400) // 閲覧権限がない場合は、ステータスを400に設定
      } else if (error.response.status === 401) {
        if (error.response.data.message === 'Password blank or incorrect.') {
          setStatusCode(401)
        }
        else {
          showMessage({
            text: '401エラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
            status: 'error',
          })
        }
      } else if (error.response.status === 500) {
        showMessage({
          text: '500サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      } else {
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      }
    }
  }, [])

  return { fetchAuth }
}
