import { usePlayerData } from './usePlayerData'

export const useSceneNumberToId = () => {
  const { listSpaceData } = usePlayerData() // list space のデータ

  /**
   * sceneNumberをIDに変換
   * @param spaceId spaceId
   * @param sceneNumber sceneの順番
   * @return sceneId
   */
  const sceneNumberToId = (spaceId: number, sceneNumber: number): number | null => {
    let sceneId: null | number = null
    for (let i = 0; i < listSpaceData.length; i++) {
      const space = listSpaceData[i]

      if (space.spaceId === spaceId) {
        if (space.scenes && sceneNumber >= 0 && sceneNumber < space.scenes.length) {
          sceneId = space.scenes[sceneNumber]?.['sceneId']
        } else {
          return sceneId
        }
        break
      }
    }
    if (sceneId === null) {
      alert('SceneIDが存在しません')
    }
    return sceneId
  }
  return { sceneNumberToId }
}
