import { useEffect, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useMessage } from './useMessage'
import { viewId } from '../services/generateViewId'
import { createViewingHistory } from '../graphql/createViewingHistory'
import { getVideoCodec } from '../services/videoCodec'
import { usePlayerData } from './usePlayerData'
import { VIEW_TYPE } from '../config/playerData'

const addViewingHistoryMutation = gql`
  mutation createViewingHistory($input: CreateViewingHistoryInput!) {
    createViewingHistory(input: $input) {
      volume
      sceneId
    }
  }
`

export const useViewingHistory = () => {
  const [addViewingHistory, { error }] = useMutation(addViewingHistoryMutation)
  const { showMessage } = useMessage()
  const { viewType } = usePlayerData()
  const codec = useMemo(() => getVideoCodec(), [])

  useEffect(() => {
    if (!error) {
      return
    }

    if (error.networkError && 'statusCode' in error.networkError) {
      const statusCode = error.networkError.statusCode
      showMessage({
        text: `${statusCode}サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。`,
        status: 'error',
      })
      return
    }
    showMessage({
      text: 'エラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
      status: 'error',
    })
  }, [error])

  const addHistory = async (sceneId: number) => {
    if (viewType === VIEW_TYPE.PREVIEW) {
      return
    }
    const input: createViewingHistory = {
      sceneId: sceneId,
      viewId: viewId,
      codec,
    }

    try {
      await addViewingHistory({ variables: { input } })
    } catch (error) {}
  }

  return { addHistory }
}
