type getVideoCodecType = () => 'H265_HLS' | 'H265_DASH' | 'VP9'

const codecH265 = 'application/vnd.apple.mpegurl; codecs="hvc1.1.6.L93.90"'
const codecDash = 'video/mp4; codecs="hev1.1.6.L93.90"'

export const getVideoCodec: getVideoCodecType = () => {
  // Edge,Androidはwindow.MediaSource.isTypeSupported(codecDash)がtrueを返すが、
  // 実際に再生するとエラーになるため、UAで判定し、VP9を返す
  const agent = window.navigator.userAgent.toLowerCase()
  if (agent.indexOf('edg') > 0 || agent.indexOf('edge') > 0 || agent.indexOf('android') > 0) {
    return 'VP9'
  }

  let video: any = document.createElement('video')
  if (video.canPlayType(codecH265) === 'probably' || video.canPlayType(codecH265) === 'maybe') {
    video = null
    // Safari
    return 'H265_HLS'
  } else {
    video = null
    if (window.MediaSource && typeof window.MediaSource.isTypeSupported === 'function') {
      if (window.MediaSource.isTypeSupported(codecDash)) {
        return 'H265_DASH'
      }
    }
  }
  return 'VP9'
}
