import { useCallback } from 'react'

export const useDateFormat = () => {
  const dateFormat = useCallback((dateString: string) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}/${month}/${day}`
  }, [])

  return { dateFormat }
}
