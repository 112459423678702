import { Box, BoxProps } from '@chakra-ui/react'
import { memo, FC } from 'react'

export const Body2: FC<BoxProps> = memo(({ children, ...props }) => {
  return (
    <Box fontSize="14px" lineHeight="24px" fontWeight="400" {...props}>
      {children}
    </Box>
  )
})
