import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

export type IsPreviousPausedContextType = {
  isPreviousPaused: boolean
  setIsPreviousPaused: Dispatch<SetStateAction<boolean>>
}

export const IsPreviousPausedContext = createContext<IsPreviousPausedContextType>({} as IsPreviousPausedContextType)

export const IsPreviousPausedProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [isPreviousPaused, setIsPreviousPaused] = useState(false)

  return (
    <IsPreviousPausedContext.Provider value={{ isPreviousPaused, setIsPreviousPaused }}>
      {children}
    </IsPreviousPausedContext.Provider>
  )
}
